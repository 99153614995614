import React from "react"
import { Col, Container, Row } from "react-bootstrap"
import bannerIllus from "./banner.webp"
import Title from "../common/title"
import { useIntl } from "gatsby-plugin-react-intl"
import Slider from "react-slick"
import { graphql, useStaticQuery } from "gatsby"
import { getSrc } from "gatsby-plugin-image"
import StyledButton from "../common/styledButton"

const BannerBlogHome = (props) => {
  const intl = useIntl()

  const data = useStaticQuery(graphql`
    query RecentArticlesBannerQuery {
      WPPostsEN: allWpPost(
        limit: 3
        sort: { fields: date, order: DESC }
        filter: { language: { code: { eq: EN } } }
      ) {
        nodes {
          title
          uri
          author {
            node {
              name
            }
          }
          featuredImage {
            node {
              gatsbyImage(width: 1920, formats: WEBP)
              publicUrl
              sourceUrl
            }
          }
          date
        }
      }
      WPPostsFR: allWpPost(
        limit: 3
        sort: { fields: date, order: DESC }
        filter: { language: { code: { eq: FR } } }
      ) {
        nodes {
          title
          uri
          author {
            node {
              name
            }
          }
          featuredImage {
            node {
              gatsbyImage(width: 1920, formats: WEBP)
              publicUrl
              sourceUrl
            }
          }
          date
        }
      }
    }
  `)

  let recentPosts
  switch (props.language) {
    case "FR":
      recentPosts = data.WPPostsFR.nodes
      break
    default:
      recentPosts = data.WPPostsEN.nodes
  }

  const settings = {
    autoplay: false,
    dots: true,
    infinite: true,
    fade: true,
    speed: 1500,
    lazyLoad: true,
    autoplaySpeed: 3500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
  }

  return (
    <section className="mainBanner heightSmall bannerBlog">
      <Slider {...settings}>
        {
          recentPosts.map((post, index) => (
            <div className="slider-element">
              <div
                className="mainBanner-infos"
                style={{
                  backgroundImage:
                    "linear-gradient(180deg, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0) 75%), url(" +
                      post.featuredImage.node.sourceUrl +
                    ")",
                }}
              >
                <Container>
                  <Row>
                    <Col md={10} xs={12}>
                      <div>
                        {/* <span className="caption">{props.caption} </span> */}
                        <Title
                          heading={index === 0 ? 1 : 2}
                          size={2}
                          extraClass="mainTitle"
                          text={post.title}
                        />
                        {/* <div className="description">{current.subtitle}</div> */}
                        <div>
                          <StyledButton
                            title={props.language === "EN" ? "Discover" : "Découvrir"}
                            type="link"
                            path={`/blog${post.uri}`}
                          />
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Container>
                <span className="scrollElem"></span>
              </div>
            </div>
          ))
        }
      </Slider>
    </section>
  )
}

export default BannerBlogHome
