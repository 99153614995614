import React from "react"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import { graphql, useStaticQuery } from "gatsby"
import { navigate, useIntl } from "gatsby-plugin-react-intl"
import MainListArticles from "../../components/blog/mainListArticles"
import LayoutBlog from "../../components/blog/layoutBlog"
//import Pagination from "../../components/blog/pagination"
import Breadcrumbs from "../../components/common/breadcrumbs/breadcrumbs"
import { useFlexSearch } from "react-use-flexsearch"
import Pagination from "@mui/material/Pagination"
import Stack from "@mui/material/Stack"
import { setSeoData } from "../../services/seo.service"
import HTMLReactParser from "html-react-parser"
import BannerBlogHome from "../../components/blog/bannerBlogHome"

export default function BlogListing({
  data: { allWpPost, localSearchPages, allBlockContentBlockNews },
  pageContext,
}) {
  const isSSR = typeof window === "undefined"

  let params
  let query = ""

  if (!isSSR) {
    // Get query string from url
    params = new URLSearchParams(window.location.search.slice(1))
    query = params.get("s") || ""
  }

  let index, store

  if (localSearchPages) {
    index = localSearchPages.index
    store = localSearchPages.store
  }

  let results = useFlexSearch(query, index, store)

  results =
    results.length > 0 ? results.filter(post => post.type === "WpPost") : []
  const intl = useIntl()

  const title =
    pageContext.localeBlog.toLowerCase() === "en"
      ? "Veranda Resorts Blog - News about Veranda Resorts"
      : "Veranda Resorts Blog - News de Veranda Resorts"

  const seoData = setSeoData({
    lang: pageContext.localeBlog.toLowerCase(),
    title: title,
    field_seo: {
      description:
        pageContext.localeBlog.toLowerCase() === "en"
          ? "News about Veranda Resorts"
          : "News de Veranda Resorts",
      title: title,
      og_description:
        pageContext.localeBlog.toLowerCase() === "en"
          ? "News about Veranda Resorts"
          : "News de Veranda Resorts",
      //og_image: seo.opengraphImage ? process.env.GATSBY_URL + seo.opengraphImage.localFile.publicURL : "",
      og_locale: pageContext.localeBlog.toLowerCase(),
      og_title: title,
      og_type: "website",
    },
    //image: seo.opengraphImage ? process.env.GATSBY_URL + seo.opengraphImage.localFile.publicURL : "",
  })

  const blogTranslatedUrl = JSON.parse(pageContext.translatedUrls)
  const blogUrlByLocale =
    pageContext.localeBlog === "FR"
      ? blogTranslatedUrl["fr"]
      : blogTranslatedUrl["en"]
  const [page, setPage] = React.useState(1)

  const handleChange = (event, value) => {
    setPage(value)
    if (value === 1) {
      navigate(blogUrlByLocale)
    } else {
      navigate(blogUrlByLocale + "/page/" + value)
    }
  }

  let bannerBlog;

  //filter object by language
  bannerBlog = allBlockContentBlockNews.nodes.filter(
    node => node.langcode === pageContext.localeBlog.toLowerCase()
  )

  return (
    <Layout pageContext={pageContext} pageTitle={title} pageClass="pageBlog">
      <Seo data={seoData} />
      <BannerBlogHome language={pageContext.localeBlog} />
      <Breadcrumbs pageTitle="Blog" />
      <LayoutBlog language={pageContext.localeBlog}>
        <MainListArticles articles={allWpPost.nodes} results={results} />
        <Stack spacing={2}>
          <Pagination
            count={pageContext.numPages}
            page={pageContext.currentPage}
            onChange={handleChange}
          />
        </Stack>
      </LayoutBlog>
    </Layout>
  )
}

export const query = graphql`
  query ($skip: Int!, $limit: Int!, $localeBlog: WpLanguageCodeEnum!) {
    allWpPost(
      sort: { fields: date, order: DESC }
      limit: $limit
      skip: $skip
      filter: { language: { code: { eq: $localeBlog } } }
    ) {
      nodes {
        title
        uri
        id
        date
        excerpt
        language {
          slug
        }
        translations {
          language {
            slug
          }
          uri
        }
        featuredImage {
          node {
            gatsbyImage(width: 1920, formats: WEBP)
          }
        }
        categories {
          nodes {
            name
            uri
          }
        }
      }
    }
  allBlockContentBlockNews {
    nodes {
      langcode
      block_news_title
      block_news_caption
      block_news_description {
        processed
      }
      relationships {
        block_news_bg {
          relationships {
            field_image {
              publicUrl
            }
          }
        }
      }
    }
  }
    localSearchPages {
      index
      store
    }
  }
`
